<template>
  <div class="mb-3">
    <b-container>
      <b-card class="mt-4 pb-0">
        <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">{{ $t('endUser.ourServices') }}</h2>
        <service-card v-for="(service, key) in allServices" :key="key" :item="service"/>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import ServiceCard from '../components/serviceCard'
export default {
  name: 'services',
  components: { ServiceCard },
  mounted () {
    core.index()
  },
  data () {
    return {
      allServices: [{
        image: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
        routeName: 'insurance',
        title: 'تأمين',
        description: 'أطلق بيت التأمين بالشراكة مع نادي الإبل أول وثيق تأمين لنقل الإبل في المملكة، نظرًا لما تمثله الإبل من أهمية في تاريخنا، ولارتباطها بتراثنا وثقافتنا، ولأهمية رعاية الإبل، وجبت علينا المبادرة بإطلاق وثيقة تأمين نقل الإبل. حيث لم تتأخر المملكة منذ عهد المؤسس الملك عبدالعزيز بن عبدالرحمن (طيب الله ثراه) عن دعم الموروث العربي، الذي يتميز به سكان الجزيرة العربية.أطلق بيت التأمين بالشراكة مع نادي الإبل أول وثيق تأمين لنقل الإبل في المملكة، نظرًا لما تمثله الإبل من أهمية في تاريخنا، ولارتباطها بتراثنا وثقافتنا، ولأهمية رعاية الإبل، وجبت علينا المبادرة بإطلاق وثيقة تأمين نقل الإبل. حيث لم تتأخر المملكة منذ عهد المؤسس الملك عبدالعزيز بن عبدالرحمن (طيب الله ثراه) عن دعم الموروث العربي، الذي يتميز به سكان الجزيرة العربية.',
        textButton: ''
      },
      {
        image: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
        routeName: 'stores',
        title: 'المتاجر',
        description: 'أطلق بيت التأمين بالشراكة مع نادي الإبل أول وثيق تأمين لنقل الإبل في المملكة، نظرًا لما تمثله الإبل من أهمية في تاريخنا، ولارتباطها بتراثنا وثقافتنا، ولأهمية رعاية الإبل، وجبت علينا المبادرة بإطلاق وثيقة تأمين نقل الإبل. حيث لم تتأخر المملكة منذ عهد المؤسس الملك عبدالعزيز بن عبدالرحمن (طيب الله ثراه) عن دعم الموروث العربي، الذي يتميز به سكان الجزيرة العربية.أطلق بيت التأمين بالشراكة مع نادي الإبل أول وثيق تأمين لنقل الإبل في المملكة، نظرًا لما تمثله الإبل من أهمية في تاريخنا، ولارتباطها بتراثنا وثقافتنا، ولأهمية رعاية الإبل، وجبت علينا المبادرة بإطلاق وثيقة تأمين نقل الإبل. حيث لم تتأخر المملكة منذ عهد المؤسس الملك عبدالعزيز بن عبدالرحمن (طيب الله ثراه) عن دعم الموروث العربي، الذي يتميز به سكان الجزيرة العربية.',
        textButton: ''
      },
      {
        image: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
        routeName: 'veterinarian',
        title: 'البيطرة',
        description: 'أطلقت منصة إبل مبادرة جديدة لعلاج الإبل و الإهتمام بسلامتها من خلال إطلاق منصة بيطرة داخل موقع إبل، حيث تمكنك المنصة من حرية الإختيار من مجموعة من الأطباء المعتمدين سواء كنت ترغب في كشف عيادة أو زيارة منزلية أو استشارة طبية، بالإضافة إلى إمكانية صرف الأدوية للإبل الخاصة بك و تصلك إلى منزلك في الوقت الذي تفضله.',
        textButton: ''
      },
      {
        image: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
        routeName: 'transportation',
        title: 'النقل',
        description: 'تعتبر منصة إبل من المنصات المتخصصة و المتميزة في نقل الإبل بأمان وقت ما تشاء و إلى أي مكان نظرا لإيماننا بأهمية الإبل بالنسبة لتراثنا و تاريخنا السعودي العربي ولذلك وجب علينا إنشاء منصة تمكنك بسهولة من نقل الإبل. فنحن نحرص دائما على نقل الإبل بأقصى درجات الأمان و أفضل سعر ممكن.',
        textButton: ''
      },
      {
        image: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
        routeName: 'permissions',
        title: 'التصاريح',
        description: 'تسهل لك منصة إبل الحصول على تصاريح الإبل الخاصة بك موثقة من وزارة الزراعة، نظرا لما تمثله هذه التصاريح من أهمية لإمتلاك الإبل، تداولها، نقلها و فندقتها، لذلك وجب تواجد خدمة تسهل عليك استخراج , التصاريح حيث لم تتأخر المملكة عن دعم الإبل و تسهيل كل الخدمات الخاصة بها بما في ذلك إستخراج التصاريح',
        textButton: ''
      }
      ]
    }
  }
}
</script>
